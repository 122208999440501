import "./Upload.css";

const Upload = () => {
  const event = new Date();

  const allDate = event.toLocaleString("en-GB", { timeZone: "UTC" });
  console.log("IMG_" + allDate.replace(", ", "_").replace(/[/:]/g, ""));

  function previewImage(event) {
    console.log("hello");
    var input = event.target;
    var image = document.getElementById("preview");

    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        image.src = e.target.result;
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  return (
    <>
      <h1>File Upload</h1>
      <form
        method="POST"
        action="/api/file-upload"
        enctype="multipart/form-data"
      >
        <input
          type="file"
          name="file"
          id="file"
          onchange={() => previewImage(event)}
        />
        <button type="submit">Upload</button>
        <div id="preview"></div>
      </form>

      <div class="background">
        <form class="app__form">
          <header>
            <h1 class="app__heading">Talk to us!</h1>
          </header>

          <label for="full_name" class="app__label">
            First Name
          </label>
          <input type="text" name="full_name" class="app_input-text" required />

          <label for="phone_number" class="app__label">
            Phone Number
          </label>
          <input
            type="tel"
            pattern="^\d{11,13}$"
            name="phone_number"
            title="(Numbers only, inc. area code)"
            class="app_input-text"
            required
          />

          <label for="Email" class="app__label">
            Email
          </label>
          <input type="email" name="Email" class="app_input-text" required />

          <label for="message" class="app__label">
            Message<sup>(Optional)</sup>
          </label>
          <textarea name="full_name" class="app_input-textarea">
            {" "}
          </textarea>

          <div class="app__base64__fields is-hidden">
            <textarea name="full_name" class="js-cover-letter__textarea">
              {" "}
            </textarea>
            <textarea name="full_name" class="js-cv__textarea">
              {" "}
            </textarea>
          </div>

          <div class="app__uploads">
            <div class="upload__wrapper">
              <input
                type="file"
                accept=".doc, .docx, .pdf"
                id="cover-letter"
                class="app__hidden-upload"
                required
              />

              <label for="cover-letter" class="app__upload-label">
                <i class="fa fa-upload"></i>Cover letter
              </label>
            </div>

            <div class="upload__wrapper">
              <input
                type="file"
                accept=".doc, .docx, .pdf"
                id="cv"
                class="app__hidden-upload"
                required
              />

              <label for="cv" class="app__upload-label">
                <i class="fa fa-upload"></i>C.V.
              </label>
            </div>
          </div>

          <button class="app__submit">Submit</button>
        </form>
      </div>
    </>
  );
};

export default Upload;
