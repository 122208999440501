import { GoDash, GoPlus } from "react-icons/go";
import RadioInputs from "../common/RadioInputs";
import "./ProductInfoCard.css";

import { useState } from "react";
import ShowcaseStatus from "../common/ShowcaseStatus";
import Button from "../common/Button";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";

const ProductInfoCard = ({ ProductObj, onAdding }) => {
  const [quantityNum, setQuantityNum] = useState(1);

  let data = {
    id: 1,
    title: "Decorative Wooden Globe",
    description:
      "A beautifully handcrafted decorative globe, expertly made from the rich and aromatic Thuya wood. This stunning piece boasts intricate details and a smooth finish, making it a perfect addition to elevate the aesthetic of any home or office space. Its natural warm tones and unique wood grain patterns add a touch of elegance and sophistication, while the sturdy craftsmanship ensures it will be a timeless piece. Whether placed on a desk, shelf, or display table, this wooden globe serves as both a striking décor element and a conversation starter, making it an ideal gift for those who appreciate fine craftsmanship and distinctive, natural beauty.",
    category: "home decor",
    price: 45.99,
    discountPercentage: 15,
    rating: 4.8,
    stock: 3,
    tags: ["wood decor", "globe", "handcrafted"],
    weight: 1.5,
    dimensions: {
      width: 12.0,
      height: 16.0,
      depth: 12.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890123",
      qrCode: "...",
    },
    thumbnail: "path-to-thumbnail-globe.jpg",
    images: [
      "/assets/imgs/products/football with support.jpg",
      "/assets/imgs/products/football with support.jpg",
    ],
  };

  data = ProductObj;

  const minimizeText = (text, lnth) => {
    if (text.length <= lnth) return text;

    return (
      <>
        {text.slice(0, lnth) + " ... "}
        <span className="readmore">Read more</span>
      </>
    );
  };

  const stock = 40;

  const handleQtyTyping = () => {
    if (quantityNum === "") return;
    if (quantityNum > stock || quantityNum < 1) setQuantityNum(20);
  };

  const handleWhatsappMsg = () => {
    return `Hello,%20I%20saw%20this%20Product%20On%20your%20Store%0Aand%20I%20would%20love%20to%20confirm%20that%0AProduct%20Name:${data.title
      .split(" ")
      .join("%20")}%0A%0AProduct%20price:${
      data.price
    }%0AQuantity:${quantityNum}%0A%0AGreeting`;
  };

  return (
    <div className="product-info-card">
      <span className="product-category">{data.category}</span>
      <h1 className="product-title">{data.title}</h1>
      <p className="product-description">
        {minimizeText(data.description, 200)}
      </p>

      <div className="product-pricing">
        <div className="price">
          <sup
            style={{
              fontSize: "1.1rem",
              marginTop: "-4px",
              color: "#546bc559",
            }}
          >
            $
          </sup>
          {data.price.toFixed() * quantityNum}
        </div>
        <div className="percentage">
          Save {data.discountPercentage.toFixed()}%
        </div>
        <div className="msg">includes all taxes</div>
      </div>

      <div className="product-variants">
        <label htmlFor="size" className="product-variant-label">
          Size(cm):
        </label>
        <RadioInputs
          inputName={"input-1"}
          inputValues={["18*18cm", "22*22cm", "24*24cm"]}
        />
      </div>

      <ShowcaseStatus />

      <div className="product-footer">
        <div className="product-quantity">
          <div
            className="plus"
            onClick={() => {
              if (quantityNum >= stock) return;
              setQuantityNum((qty) => qty + 1);
            }}
          >
            <GoPlus />
          </div>
          <input
            type="number"
            id="quantity"
            className="product-quantity-input"
            value={quantityNum}
            onChange={(e) => setQuantityNum(e.target.value)}
            onKeyUp={handleQtyTyping}
          />
          <div
            className="minus"
            onClick={() => {
              if (quantityNum <= 1) return;
              setQuantityNum((qty) => qty - 1);
            }}
          >
            <GoDash />
          </div>
        </div>
        <div className="product-actions">
          <Button onClick={(e) => onAdding(data)}>Add to Cart</Button>
          {/* <Button>Add to Cart</Button> */}

          {/* <Link to={`https://wa.me/212703243875?text=${handleWhatsappMsg()}`}> */}
          <Link to={`https://wa.me/212703243875?text=${handleWhatsappMsg()}`}>
            <IoLogoWhatsapp
              style={{ color: "#25D366", fontSize: "1.7rem" }}
              onClick={(e) => handleWhatsappMsg}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductInfoCard;
