// import ProductCardsList from "../common/ProductCardsList";

const ProductsGrid = ({ children }) => {
  return (
    <div className="product-main">
      <h2 className="title">New Products</h2>
      <div className="product-grid">{children}</div>
    </div>
  );
};

export default ProductsGrid;
