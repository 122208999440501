import { BiSolidStar, BiStar } from "react-icons/bi";
import { BsBagPlus } from "react-icons/bs";
import { IoIosHeartEmpty } from "react-icons/io";
import { Link } from "react-router-dom";

const ProductCard = ({ productObj, onAdding }) => {
  return (
    <div className="showcase">
      <div className="showcase-banner">
        <img
          src={productObj.images[0]}
          alt={productObj.title}
          className="product-img default"
          width="300"
        />
        <img
          src={productObj.images[1]}
          alt={productObj.title}
          className="product-img hover"
          width="300"
        />

        <div className="showcase-actions">
          <button className="btn-action" onClick={(e) => onAdding(e)}>
            <BsBagPlus />
          </button>

          {/* <button className="btn-action">
            <IoIosHeartEmpty />
          </button> */}
        </div>
      </div>

      <div className="showcase-content">
        <a href="#el" className="showcase-category">
          {productObj.category}
        </a>

        <h3>
          <Link to={`/products/${productObj.id}`}>
            <span className="showcase-title">{productObj.title}</span>
          </Link>
        </h3>

        <div className="showcase-rating">
          <BiSolidStar />
          <BiSolidStar />
          <BiSolidStar />
          <BiSolidStar />
          <BiStar />
        </div>

        <div className="price-box">
          <p className="price">{productObj.price.toFixed(2)}</p>
          <del>
            {Math.floor(
              productObj.price +
                (productObj.price * productObj.discountPercentage) / 100
            ).toFixed(2)}
          </del>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
