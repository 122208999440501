import { useEffect, useState } from "react";

export function useFetch(url) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchingData = async () => {
      try {
        setIsLoading(true);
        const res = await fetch(url);
        const data = await res.json();

        console.log("data: ", data);
        //   setAllData(data);
        //   setDataDisplayed(data);
        // callback?.();

        setData(data);
      } catch (err) {
        console.log("Error Message:", err.message);

        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchingData();
  }, [url]);
  return { data, isLoading, error };
  // { data, isLoading, error: err.message };
}
