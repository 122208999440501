import DealOfTheDay from "./DealOfTheDay";

const DealOfTheDayList = ({ productsData, onAdding }) => {
  console.log("hello, mother fucker");
  const data = productsData;
  console.log("==> ", productsData);

  if (!productsData)
    return (
      <div className="product-featured">
        <h2 className="title">There is no special Deals this time!</h2>
      </div>
    );
  return (
    <>
      {data.map((el) => (
        <div className="showcase-container" key={el.id}>
          <DealOfTheDay productbj={el} onAdding={onAdding} />
        </div>
      ))}
    </>
  );
};

export default DealOfTheDayList;
