import { BiSolidStar } from "react-icons/bi";
import { IoIosStarHalf } from "react-icons/io";
import ShowcaseStatus from "./ShowcaseStatus";
import { Link } from "react-router-dom";

const DealOfTheDay = ({ productbj, onAdding }) => {
  return (
    <div className="showcase">
      <div className="showcase-banner">
        <img
          src={productbj.images[0]}
          alt={productbj.title}
          className="showcase-img"
        />
      </div>

      <div className="showcase-content">
        <div className="showcase-rating">
          <BiSolidStar />
          <BiSolidStar />
          <BiSolidStar />
          <BiSolidStar />
          <IoIosStarHalf />
        </div>

        <Link to={`/products/${productbj.id}`}>
          <h3 className="showcase-title">{productbj.title}</h3>
        </Link>

        <p className="showcase-desc">
          {productbj.description.slice(0, 350) + "..."}
        </p>

        <div className="price-box">
          <p className="price">{productbj.price.toFixed(2)} $</p>

          <del>
            {Math.floor(
              productbj.price +
                (productbj.price * productbj.discountPercentage) / 100
            ).toFixed(2)}
          </del>
        </div>

        <button className="add-cart-btn" onClick={(e) => onAdding(productbj)}>
          add to cart
        </button>

        <ShowcaseStatus />

        <div className="countdown-box">
          <p className="countdown-desc">Hurry Up! Offer ends in:</p>

          <div className="countdown">
            <div className="countdown-content">
              <p className="display-number">--</p>

              <p className="display-text">Days</p>
            </div>

            <div className="countdown-content">
              <p className="display-number">--</p>
              <p className="display-text">Hours</p>
            </div>

            <div className="countdown-content">
              <p className="display-number">--</p>
              <p className="display-text">Min</p>
            </div>

            <div className="countdown-content">
              <p className="display-number">--</p>
              <p className="display-text">Sec</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealOfTheDay;
