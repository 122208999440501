import React from "react";
import ReactDOM from "react-dom/client";
// import App from "./App";

// import {
//   BrowserRouter,
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
// import ProductPage from "./pages/ProductPage";
// import ModelSubscribe from "./components/containers/ModelSubscribe";
// import Header from "./components/layout/Header";
// import Footer from "./components/layout/Footer";
// import Upload from "./components/containers/Upload";
import MainPage from "./pages/MainPage";

// const [cartData, setCartData] = useState(productsData);

// const cartData = productsData;

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <App />,
//   },
//   {
//     path: "/productpage",
//     element: <ProductPage />,
//   },
//   {
//     path: "/upload",
//     element: <Upload />,
//   },
// ]);

import { productsData } from "./data/data";

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    {/* <BrowserRouter> */}
    {/* <ModelSubscribe />
    <Header cartData={cartData} />
    <RouterProvider router={router} />
    <Footer /> */}
    {/* </BrowserRouter> */}

    <React.StrictMode>
      <MainPage productsData={productsData} />
    </React.StrictMode>
  </>
);
