// import { wood_decors } from "../../data/data";
import { Link } from "react-router-dom";
import "./Footer.css";
import {
  FaFacebook,
  FaInstagram,
  FaPinterest,
  FaWhatsapp,
} from "react-icons/fa";

const Footer = () => {
  // const data = wood_decors;

  // console.log(data);

  return (
    <footer>
      {/* <div className="footer-category">
        <div className="container">
          <h2 className="footer-category-title">Quick Links</h2>

          {data.map((el) => (
            <div className="footer-category-box" key={el.id}>
              <h3 className="category-box-title">{el.name} :</h3>

              {el.products.map((el) => (
                <a href="#elel" className="footer-category-link" key={el}>
                  {el}
                </a>
              ))}
            </div>
          ))}
        </div>
      </div> */}

      <div className="footer-nav">
        <div className="container">
          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Popular Categories</h2>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Boxes
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Games
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Home Decor
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Souvenirs
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Clocks
              </a>
            </li>
          </ul>

          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Products</h2>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Furniture
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                New products
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Best sales
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Contact us
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Sitemap
              </a>
            </li>
          </ul>

          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Our Company</h2>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Delivery
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Legal Notice
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Terms and conditions
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                About us
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Secure payment
              </a>
            </li>
          </ul>

          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Services</h2>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Prices drop
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                New products
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Best sales
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Contact us
              </a>
            </li>

            <li className="footer-nav-item">
              <a href="#el" className="footer-nav-link">
                Sitemap
              </a>
            </li>
          </ul>

          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Contact</h2>
            </li>

            <li className="footer-nav-item flex">
              <div className="icon-box">
                <ion-icon name="location-outline"></ion-icon>
              </div>

              <address className="content">
                419 State 414 Rte Beaver Dams, New York(NY), 14812, USA
              </address>
            </li>

            <li className="footer-nav-item flex">
              <div className="icon-box">
                <ion-icon name="call-outline"></ion-icon>
              </div>

              <a href="tel:+607936-8058" className="footer-nav-link">
                (607) 936-8058
              </a>
            </li>

            <li className="footer-nav-item flex">
              <div className="icon-box">
                <ion-icon name="mail-outline"></ion-icon>
              </div>

              <a href="mailto:example@gmail.com" className="footer-nav-link">
                example@gmail.com
              </a>
            </li>
          </ul>

          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <h2 className="nav-title">Follow Us</h2>
            </li>

            <li>
              <ul className="social-link">
                <li className="footer-nav-item">
                  <a href="#el" className="footer-nav-link">
                    <ion-icon name="logo-facebook"></ion-icon>
                  </a>
                </li>

                <li className="footer-nav-item">
                  <a href="#el" className="footer-nav-link">
                    <ion-icon name="logo-twitter"></ion-icon>
                  </a>
                </li>

                <li className="footer-nav-item">
                  <a href="#el" className="footer-nav-link">
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </a>
                </li>

                <li className="footer-nav-item">
                  <a href="#el" className="footer-nav-link">
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container footer-bottom-container">
          <p className="copyright">Site in progress.</p>
          <p className="developer-Links">
            <span>Ask developer:</span>
            {/* <span>Contact developer on:</span> */}

            <ul className="footer-social-container">
              <li>
                <Link
                  className="social-link"
                  to="https://wa.me/212703243875"
                  target="_blank"
                  style={{
                    "--social-link-bgcolor": "#25D366",
                  }}
                >
                  <FaWhatsapp />
                </Link>
              </li>

              <li>
                <Link
                  className="social-link"
                  to={"https://www.instagram.com/redone.sarhane/"}
                  target="_blank"
                  style={{
                    "--social-link-bgcolor":
                      "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB50 90%)",
                  }}
                >
                  <FaInstagram />
                </Link>
              </li>
            </ul>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
