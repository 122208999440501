import BannerOffer from "../sections/BannerOffer";
import ProductsGrid from "../sections/ProductsGrid";
import ProductFeatured from "../sections/ProductFeatured";

import ProductCardsList from "../common/ProductCardsList";
import DealOfTheDayList from "../common/DealOfTheDayList";

const Main = ({ productsData, onAdding }) => {
  console.log("products: ", productsData);
  console.log("products: ", productsData.slice(0, 2).reverse());
  return (
    <main>
      <div className="banner">
        <div className="container">
          <BannerOffer />
        </div>
      </div>
      {/* <div className="category">
          <div className="container"></div>
        </div> */}
      <div className="product-container">
        <div className="container">
          {/* <div className="sidebar has-scrollbar" data-mobile-menu></div> */}
          <div className="product-box">
            <ProductFeatured>
              <DealOfTheDayList
                productsData={productsData.slice(0, 2).reverse()}
                onAdding={onAdding}
              />
            </ProductFeatured>
            <ProductsGrid>
              <ProductCardsList
                productsData={productsData}
                onAdding={onAdding}
              />
            </ProductsGrid>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Main;
