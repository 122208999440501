import { Link } from "react-router-dom";
import "./CartOrderShort.css";
import { LiaTimesSolid } from "react-icons/lia";

const CartOrderShort = ({ productData, onDelete }) => {
  // console.log("here is the data:: ");
  // console.log("here is the data:: ", productData);

  return (
    <div className="cart-order-short">
      <div className="remove" onClick={onDelete}>
        <LiaTimesSolid />
      </div>
      <div className="thumbail">
        <img
          src={productData.thumbnail}
          // srcSet={productData.thumbnail}
          alt={productData.title}
          srcset=""
        />
      </div>
      <div className="details">
        {/* <div className="details-title">hello title here</div> */}

        <Link to={`/products/${productData.id}`}>
          <div className="details-title">{productData.title}</div>
        </Link>
        <div className="details-description">
          {/* {productData.description.slice(0, 20) + "..."} */}
          {productData.category}
        </div>
      </div>
      <div className="qty"></div>
      <div className="price">{productData.price}$</div>
    </div>
  );
};

export default CartOrderShort;
