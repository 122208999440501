import Header from "../components/layout/Header";

const HeaderChildrenFooter = ({ children }) => {
  return (
    <>
      {/* <Header    /> */}
      {children}
    </>
  );
};

export default HeaderChildrenFooter;
