import { LiaTimesSolid } from "react-icons/lia";
import "./Notification.css";
import { useEffect, useState } from "react";

const Notification = ({ title, message, color, action, onClearNot }) => {
  const [line, setLine] = useState(20);

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    (async function (timing) {
      let delayTime = timing / 100;

      for (let i = timing; i >= 0; i = i - delayTime) {
        let x = (i * 100) / timing;
        setLine(x);
        await delay(delayTime); // Waits ]Delay time second between iterations
      }
      onClearNot();
    })(3000);
  }, []);

  return (
    <>
      <div className="toast-panel">
        <div className="toast-item help">
          <div className="toast help">
            <button className="close">
              <LiaTimesSolid />
            </button>

            <div className="line-before" style={{ background: color }}></div>
            <div className="toast-body">
              <h3 style={{ color: color }}>{title}</h3>
              <p>
                {message}
                {action && (
                  <span className="action" onClick={action[1]}>
                    {action[0]}
                  </span>
                )}
                .{/* {line} */}
              </p>
            </div>

            <div className="process" style={{}}>
              <div
                className="process-line"
                style={{ background: color, width: `${line}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
