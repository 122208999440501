import "./LinkBtn.css";

const LinkBtn = ({ children, active = false, ...rest }) => {
  return (
    <button className={`link-btn${active ? " active" : ""}`} {...rest}>
      {children}
    </button>
  );
};

export default LinkBtn;
