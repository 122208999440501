import "./CartDrawer.css";
import Button from "../common/Button";
import { LiaTimesSolid } from "react-icons/lia";
import CartOrderShort from "../common/CartOrderShort";
import { FaRegFaceLaugh } from "react-icons/fa6";
import { useEffect, useState } from "react";

const CartDrawer = ({ data, onDelete, isCardOpen, handleCardToggle }) => {
  const [total, setTotal] = useState({
    subTotal: 0,
    shipping: 0,
    total: 0,
  });

  useEffect(() => {
    let shipp = 4.97;

    if (data.length === 0) {
      shipp = 0;
      setTotal({
        subTotal: 0,
        shipping: 0,
        total: 0,
      });
      return;
    }

    //this is is for big free shipping deals
    if (data.length > 5) shipp = 0;

    const subTt = data.reduce((el, current) => {
      return el + current.price;
    }, 0);

    const tt = subTt + shipp;

    setTotal((e) => {
      return { subTotal: subTt, shipping: shipp, total: tt };
    });
  }, [data]);

  return (
    <div className={`card-drawer${isCardOpen ? " active" : " close"}`}>
      <div
        className="card-overlay"
        onClick={() => handleCardToggle(false)}
      ></div>
      <div
        className="card-content"
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
      >
        <div className="cart-content-container">
          <div className="card-content-header">
            <h2>Your cart</h2>
            <LiaTimesSolid
              style={{ cursor: "pointer", fontSize: "1.4rem" }}
              onClick={() => handleCardToggle(false)}
            />
          </div>
          <div className="card-content-body">
            {data.length > 0 ? (
              data.map((el, i) => (
                <CartOrderShort
                  key={i}
                  productData={el}
                  onDelete={(e) => onDelete(el.id)}
                />
              ))
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.7rem",
                }}
              >
                <span style={{ fontSize: "2.2rem" }}>
                  <FaRegFaceLaugh />
                </span>
                <span>Have Fun and Discover Our product!</span>
              </div>
            )}
          </div>
          <div className="card-content-footer">
            <div className="card-footer-detail subtotal">
              <span className="card-detail-title">SubTotal</span>
              <span className="card-detail-content">
                {total.subTotal.toFixed(2)}$
              </span>
            </div>
            <div className="card-footer-detail shipping">
              <span className="card-detail-title">Shipping</span>
              <span className="card-detail-content">
                {total.shipping.toFixed(2)}$
              </span>
            </div>
            <div className="card-footer-detail total">
              <span className="card-detail-title">Total</span>
              <span className="card-detail-content">
                {total.total.toFixed(2)}$
              </span>
            </div>
            <div className="card-footer-cta">
              <Button bgColor="#25D366">Whatsapp</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartDrawer;
