import "./ProductPage.css";
import ImageGallery from "../components/containers/ImageGallery";
import ProductInfoCard from "../components/containers/ProductInfoCard";
import ProductMoreDetails from "../components/containers/ProductMoreDetails";
import ProductsGrid from "../components/sections/ProductsGrid";
import { useEffect, useState } from "react";

// import { productsData } from "../data/data";

import React from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import ProductCardsList from "../components/common/ProductCardsList";

import { productsData as productsDataComingFromDB } from "../data/data";

let products = [
  {
    id: 1,
    name: "Product 1",
    description: "Detailed description of product 1",
  },
  {
    id: 2,
    name: "Product 2",
    description: "Detailed description of product 2",
  },
];

products = productsDataComingFromDB;

const ProductPage = ({ productsData, onAdding }) => {
  const [data, setData] = useState({
    title: "title here!",
    images: ["/assets/imgs/products/chess roba3iya.png"],
  });

  const { id } = useParams();
  const product = products.find((p) => p.id === parseInt(id));

  // Scroll to top on component render
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = product.title;

    // CLean Up function ALways run after the component unmounted or unrendered
    return () => {
      document.title = "ByDaoudi | Home";
    };
  }, [product]);

  if (!product) return <h2>Product not found</h2>;

  // useEffect(() => {
  //   const getData = async () => {
  //     const res = await fetch("https://dummyjson.com/products/1");
  //     const data = await res.json();

  //     console.log(data);

  //     console.log("immmm", data.images[0]);

  //     setData(data);
  //   };
  //   getdata();
  // }, []);

  return (
    <>
      <div className="product-page">
        <div className="container product-comp">
          <div className="product-location">
            <span className="product-location-dir">
              <Link to={"/"}>Home</Link>
            </span>
            <span className="product-location-dir">{product.category}</span>
            <span className="product-location-dir active">{product.title}</span>
          </div>

          <div className="product-gallery">
            {/* <ImageGallery imagesObj={data.images} /> */}
            <ImageGallery imagesObj={product.images} />
          </div>

          <div className="product-info-card">
            <ProductInfoCard ProductObj={product} onAdding={onAdding} />
          </div>

          {/* <div className="product-other-details">
            <ProductMoreDetails /> 
            <div className="product-preview-details">here is the data</div>
          </div> */}

          <div className="related-products">
            {/* <h1>Related Products</h1> */}

            <ProductsGrid>
              <ProductCardsList
                productsData={productsData}
                onAdding={onAdding}
              />
            </ProductsGrid>
          </div>
          {/* <div className="product-page">
          <nav className="breadcrumbs">
            <a href="/" className="breadcrumbs__link">
              Home
            </a>
            <a href="/shop" className="breadcrumbs__link">
              Shop
            </a>
            <span className="breadcrumbs__current">Product Name</span>
          </nav>

          <section className="product-description">
            <h2 className="product-description__title">Product Details</h2>
            <div className="product-description__content">
              <p>
                Detailed information about the product, materials, features,
                etc.
              </p>
            </div>
          </section>

          <section className="product-reviews">
            <h2 className="product-reviews__title">Customer Reviews</h2>
            <div className="product-reviews__list">
              <div className="product-review">
                <p className="product-review__text">"Great product!"</p>
                <p className="product-review__author">- Customer Name</p>
              </div>
            </div>
          </section>

          <section className="related-products">
            <h2 className="related-products__title">You May Also Like</h2>
            <div className="related-products__list">
              <div className="related-product-card">
                <img
                  src="related1.jpg"
                  alt="Related Product 1"
                  className="related-product-card__image"
                />
                <p className="related-product-card__name">Related Product 1</p>
                <p className="related-product-card__price">$49.99</p>
              </div>
            </div>
          </section>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default ProductPage;
