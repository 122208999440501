import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ModelSubscribe from "../components/containers/ModelSubscribe";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import ProductPage from "./ProductPage";
import App from "../App";
import Upload from "../components/containers/Upload";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Notification from "../components/common/Notification";
import Home from "../backend/admin/Home";
import HeaderChildrenFooter from "./HeaderChildrenFooter";

//hooks
import { useLocalStorageState } from "../hooks/useLocalStorageState";

let productsDataCardDrawerTestInitial = [
  {
    id: 851,
    title: "Decorative Wooden Globe",
    description:
      "A stunning decorative wooden globe made from Thuya wood, perfect for enhancing any home or office space.",
    category: "home decor",
    price: 45.99,
    thumbnail: "/assets/imgs/products/football with support.jpg",
  },
  {
    id: 25416,
    title: "Thuya Wood Chess Set",
    description:
      "A beautifully hand-carved chess set featuring pieces made from Thuya wood. Ideal for game enthusiasts and collectors.",
    category: "games",
    price: 79.99,
    thumbnail: "/assets/imgs/products/chess roba3iya.png",
  },
];

productsDataCardDrawerTestInitial = [];

const MainPage = ({ productsData }) => {
  console.log("productsData", productsData);
  // const [cartData, setCartData] = useState(productsDataCardDrawerTestInitial);

  // const [cartData, setCartData] = useState(productsDataCardDrawerTestInitial);
  const [cartData, setCartData] = useLocalStorageState([],
    
    "cartProducts");
  // const [s, t] = useLocalStorageState();

  const [isCardOpen, setisCardOpen] = useState(false);

  const [notifications, setNotifications] = useState([]);

  const handleCardToggle = (e) => {
    setisCardOpen(e);
  };

  const handleDelete = (elId) => {
    const dataNew = cartData
      .slice()
      .filter((el) => (el.id !== elId ? el : null));

    // if (dataNew.length === 0) {
    //   setCartData([
    //     {
    //       id: 6,
    //       title: "Octagonal Thuya Wood Box",
    //       description:
    //         "An intricately designed octagonal box made from Thuya wood, perfect for storing jewelry or small trinkets.",
    //       category: "jewelry boxes",
    //       price: 39.99,
    //       thumbnail: "/assets/imgs/products/sedo9a temaniya mkherma.png",
    //     },
    //   ]);
    //   return;
    // }

    setCartData(dataNew);
  };

  let notId = 0;
  const handleAdding = (el) => {
    if (!el) return;

    const isExist =
      cartData.slice().filter((element) => el.id === element.id).length > 0;

    console.log(isExist);

    if (isExist) {
      setNotifications((e) => {
        const xx = [
          // ...e,
          {
            id: `not_${notId}_${el.id}`,
            title: "Product Exist!",
            message: `_${el.title}_ already ADDED to Card`,
            color: "#ff9966",
            action: ["See Details", (e) => handleCardToggle(true)],
          },
        ];

        notId++;

        return xx;
      });

      // setTimeout(() => {
      //   setNotifications([]);
      // }, 3000);
      return;
    }

    setCartData((e) => {
      return [
        ...e,
        {
          id: el.id,
          title: el.title,
          description: el.description,
          category: el.category,
          price: el.price,
          thumbnail: el.thumbnail,
        },
      ];
    });

    setNotifications((e) => {
      const xx = [
        // ...e,
        {
          id: `not_${notId}_${el.id}`,
          title: "card Update!",
          message: `_${el.title}_ Added to Card`,
          color: "#339900",
          action: ["See Details", (e) => handleCardToggle(true)],
        },
      ];

      notId++;

      return xx;
    });
  };

  const handleClearNot = () => {
    setNotifications([]);
  };

  // useEffect(() => {}, [cartData]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <HeaderChildrenFooter>
          <Header
            cartData={cartData}
            isCardOpen={isCardOpen}
            handleCardToggle={handleCardToggle}
            onDelete={handleDelete}
          />
          <App productsData={productsData} onAdding={handleAdding} />
          <Footer />
        </HeaderChildrenFooter>
      ),
    },
    {
      path: "/products/:id",
      element: (
        <HeaderChildrenFooter>
          <Header
            cartData={cartData}
            isCardOpen={isCardOpen}
            handleCardToggle={handleCardToggle}
            onDelete={handleDelete}
          />
          {/* <App productsData={productsData} onAdding={handleAdding} />{" "} */}
          <ProductPage productsData={productsData} onAdding={handleAdding} />
          <Footer />
        </HeaderChildrenFooter>
      ),
    },
    {
      path: "/upload",
      element: <Upload />,
    },
    {
      path: "/admin",
      element: <Home />,
    },
    {
      path: "/newhome",
      element: (
        <HeaderChildrenFooter>
          <Header
            cartData={cartData}
            isCardOpen={isCardOpen}
            handleCardToggle={handleCardToggle}
            onDelete={handleDelete}
          />
          <App productsData={productsData} onAdding={handleAdding} />
          <Footer />
        </HeaderChildrenFooter>
      ),
    },
  ]);

  return (
    <>
      {/* 
      <Header cartData={cartData} onDelete={handleDelete} />
      <RouterProvider router={router} />
      <Footer /> */}

      <RouterProvider router={router} />

      {notifications.map((el) => (
        <Notification
          key={el.id}
          title={el.title}
          message={el.message}
          color={el.color}
          action={el.action}
          onClearNot={handleClearNot}
        />
      ))}
      <ModelSubscribe onSubmiting={setNotifications} />

      {/* <RouterProvider router={router}>
        <ModelSubscribe />
        <Header cartData={cartData} onDelete={handleDelete} />
        <Footer />
      </RouterProvider> */}
    </>
  );
};

export default MainPage;
