import { RightPanel, DisplayPanel } from "./RightPanel";

export default function Home() {
  return (
    <div
      style={{
        backgroundColor: "#d7d9dd",
        display: "flex",
      }}
    >
      <RightPanel />
      <DisplayPanel />
    </div> 
  );
}
