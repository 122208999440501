import "./tailwind-output.css";
import "./app.css";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

// import Select from "./components/common/Select";
// import { Button } from "@rewind-ui/core";
// import Main from "./components/layout/Main";

import Main from "./components/layout/Main";
import ModelSubscribe from "./components/containers/ModelSubscribe";
import ProductPage from "./pages/ProductPage";
import Notification from "./components/common/Notification";

function App({ productsData, onAdding }) {
  return (
    <>
      <Main productsData={productsData} onAdding={onAdding} />

      {/* <ProductPage /> */}
    </>
  );
}

export default App;
