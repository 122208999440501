const productsData = [
  {
    id: 1,
    title: "Decorative Wooden Globe",
    description:
      "A stunning decorative wooden globe, meticulously handcrafted from premium Thuya wood, offers a perfect blend of elegance and craftsmanship, making it a captivating addition to any home or office space. The globe’s surface showcases the natural beauty of Thuya wood, renowned for its deep amber hues and intricate grain patterns, creating a warm, luxurious feel. Each curve of the globe is carefully polished to a smooth, glossy finish, highlighting the wood's rich texture and golden-brown tones, making it both a functional and decorative masterpiece. The wooden globe is not only a symbol of sophistication but also a statement of refined taste, perfect for those who appreciate the artistry of fine woodworking. Its timeless design fits seamlessly into a variety of décor styles, from modern minimalist spaces to traditional offices or living rooms. Whether displayed on a desk, bookshelf, or mantel, this Thuya wood globe enhances the ambiance with its natural charm, making it an ideal conversation piece. A true work of art, this globe combines beauty, durability, and craftsmanship, ensuring that it stands as a cherished item for generations to come.",
    category: "home decor",
    price: 45.99,
    discountPercentage: 15,
    rating: 4.8,
    stock: 3,
    tags: ["wood decor", "globe", "handcrafted"],
    weight: 1.5,
    dimensions: {
      width: 12.0,
      height: 16.0,
      depth: 12.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890123",
      qrCode: "...",
    },
    thumbnail: "/assets/imgs/products/football with support.jpg",
    images: [
      "/assets/imgs/products/football with support.jpg",
      "/assets/imgs/products/football with support.jpg",
    ],
  },
  {
    id: 2,
    title: "Thuya Wood Chess Set",
    description:
      "A beautifully hand-carved chess set, masterfully crafted from exquisite Thuya wood, offering both aesthetic appeal and a tactile experience for game enthusiasts and collectors alike. Each chess piece is carefully shaped by skilled artisans, highlighting the rich, natural grain and warm, honey-brown tones that Thuya wood is renowned for. The intricate details of the carving showcase the unique characteristics of this rare wood, which is native to the Atlas Mountains and prized for its aromatic scent and luxurious appearance. This chess set is not only designed for gameplay but also serves as an elegant display piece, adding a touch of sophistication to any room. The board, made to complement the artistry of the pieces, enhances the overall luxurious feel of the set. Whether you're a seasoned player or a collector of fine, hand-crafted items, this chess set combines functionality with art, making it a perfect centerpiece for your home or office. Its timeless beauty and enduring quality ensure that it will be treasured for years to come, making every game an elevated experience.",
    category: "games",
    price: 79.99,
    discountPercentage: 10,
    rating: 4.9,
    stock: 5,
    tags: ["chess", "games", "wood"],
    weight: 3.5,
    dimensions: {
      width: 30.0,
      height: 5.0,
      depth: 30.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890124",
      qrCode: "...",
    },
    thumbnail: "/assets/imgs/products/chess roba3iya.png",
    images: [
      "/assets/imgs/products/chess roba3iya.png",
      "/assets/imgs/products/chess roba3iya 2.png",
    ],
  },
  {
    id: 3,
    title: "Thuya Wood Tissue Box",
    description:
      "An elegant tissue box crafted from the finest Thuya wood, meticulously carved by skilled artisans to showcase the natural beauty and rich grain of the wood. Known for its unique golden-brown hues and distinctive, aromatic scent, Thuya wood brings a sense of warmth and luxury to any setting. This tissue box is not just a practical item but also a refined decorative piece that enhances the ambiance of any room, whether it's placed on a desk, nightstand, or coffee table. Its smooth finish and detailed craftsmanship make it a perfect blend of functionality and art, adding a sophisticated touch to both modern and traditional interiors. With its timeless design and sturdy construction, this tissue box is a statement of elegance and fine taste, ideal for those who appreciate the beauty of natural materials and the skilled handiwork of master craftsmen.",
    category: "home decor",
    price: 29.99,
    discountPercentage: 5,
    rating: 4.7,
    stock: 8,
    tags: ["home decor", "tissue box", "wood"],
    weight: 2.0,
    dimensions: {
      width: 25.0,
      height: 10.0,
      depth: 10.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890125",
      qrCode: "...",
    },
    thumbnail: "/assets/imgs/products/kniniks mkherma.png",
    images: [
      "/assets/imgs/products/kniniks mkherma.png",
      "/assets/imgs/products/kniniks mkherma.png",
    ],
  },
  {
    id: 4,
    title: "Thuya Wood Jewelry/Magic Box",
    description:
      "A handcrafted Thuya wood jewelry or magic box, designed with exquisite attention to detail, is a perfect blend of beauty and mystery, ideal for storing small treasures. Made from the finest Thuya wood, this box boasts rich, warm hues and intricate wood grain patterns that make it a visually stunning piece. The box’s smooth, polished finish enhances the wood's natural luster, while the aromatic scent unique to Thuya adds an extra touch of luxury. What sets this box apart is its clever secret lock mechanism, designed to conceal its contents with a touch of magic. Whether it's used to store jewelry, keepsakes, or personal mementos, this box adds an element of intrigue and craftsmanship to your space. The hidden lock adds a layer of protection, making it not only a decorative item but also a secure place for your most precious belongings. Perfect as a thoughtful gift or a personal keepsake, this Thuya wood box combines artistry, functionality, and the timeless allure of a mystery, making it a unique addition to any home.",
    category: "jewelry boxes",
    price: 49.99,
    discountPercentage: 7,
    rating: 4.85,
    stock: 7,
    tags: ["jewelry box", "magic box", "wood"],
    weight: 1.2,
    dimensions: {
      width: 15.0,
      height: 8.0,
      depth: 10.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890126",
      qrCode: "...",
    },
    thumbnail: "/assets/imgs/products/boit magic b sarout.png",
    images: [
      "/assets/imgs/products/boit magic b sarout.png",
      "/assets/imgs/products/boit magic b sarout.png",
    ],
  },
  {
    id: 5,
    title: "Thuya Wood Apple-Shaped Decor",
    description:
      "A beautifully crafted fruit-shaped wooden decor piece, designed in the form of an apple and carved from luxurious Thuya wood, is the perfect accent for any home or office space. The natural richness of the wood's golden-brown tones and its intricate grain patterns make this apple-shaped decor not only a symbol of elegance but also a warm, inviting focal point in any room. Hand-carved by skilled artisans, the smooth finish and polished surface highlight the exceptional craftsmanship and the unique beauty of Thuya wood, known for its durability and its aromatic, cedar-like fragrance. Whether displayed on a coffee table, bookshelf, or desk, this apple-shaped piece adds a touch of nature-inspired sophistication, blending effortlessly with both modern and traditional décor. Its timeless design makes it a charming conversation starter, ideal for enhancing the ambiance of living rooms, offices, or even kitchens. This decor piece offers a perfect mix of nature, art, and fine craftsmanship, making it a thoughtful gift or a delightful personal addition to your collection.",
    category: "home decor",
    price: 24.99,
    discountPercentage: 5,
    rating: 4.6,
    stock: 6,
    tags: ["home decor", "apple", "wood"],
    weight: 1.0,
    dimensions: {
      width: 10.0,
      height: 12.0,
      depth: 10.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890127",
      qrCode: "...",
    },
    thumbnail: "/assets/imgs/products/apple decor close.png",
    images: [
      "/assets/imgs/products/apple decor close.png",
      "/assets/imgs/products/apple decor close.png",
    ],
  },
  {
    id: 6,
    title: "Octagonal Thuya Wood Box",
    description:
      "An intricately designed octagonal box, handcrafted from the finest Thuya wood, offers both beauty and functionality, making it a perfect storage solution for jewelry or small trinkets. The unique octagonal shape sets this box apart, with each side carefully carved and polished to highlight the exquisite grain patterns and deep amber hues of Thuya wood, a material prized for its luxurious appearance and natural aromatic fragrance. The smooth, glossy finish reflects the craftsmanship involved in creating a piece that is as much a work of art as it is a practical accessory. The lid of the box fits snugly, ensuring the safekeeping of its contents, while the interior is lined with a soft material to protect delicate items like rings, earrings, or keepsakes. This elegant box not only provides a secure place for your valuables but also serves as a refined decorative piece, adding a touch of sophistication to any dresser, vanity, or shelf. Whether used as a thoughtful gift or a personal treasure, this octagonal Thuya wood box combines timeless design with the natural beauty of the wood, making it an enduring keepsake that will be cherished for generations.",
    category: "jewelry boxes",
    price: 39.99,
    discountPercentage: 10,
    rating: 4.9,
    stock: 4,
    tags: ["jewelry box", "wood", "storage"],
    weight: 1.3,
    dimensions: {
      width: 18.0,
      height: 10.0,
      depth: 18.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890128",
      qrCode: "...",
    },
    thumbnail: "/assets/imgs/products/sedo9a temaniya mkherma.png",
    images: [
      "/assets/imgs/products/sedo9a temaniya mkherma.png",
      "/assets/imgs/products/sedo9a temaniya mkherma.png",
    ],
  },
  {
    id: 7,
    title: "Thuya Wood Letter Holder",
    description:
      "A stylish and functional letter holder, expertly crafted from luxurious Thuya wood, provides both elegance and practicality, making it the perfect solution for keeping your desk organized. The rich, golden-brown hues and intricate grain patterns of the wood give this letter holder a sophisticated, natural appeal, instantly elevating the aesthetics of any office or workspace. Each compartment is carefully carved and polished, offering ample space to neatly store letters, envelopes, or important documents, while the smooth finish enhances the overall tactile experience. In addition to its functionality, this Thuya wood letter holder serves as a refined desk accessory that combines timeless design with modern utility. Its compact yet sturdy construction makes it a durable and long-lasting organizational tool, ensuring your workspace remains clutter-free while adding a touch of artisanal charm. Whether used in a home office, study, or corporate setting, this letter holder embodies the perfect balance of style and practicality, making it a thoughtful gift or a personal addition to your collection of fine desk accessories.",
    category: "desk accessories",
    price: 34.99,
    discountPercentage: 5,
    rating: 4.7,
    stock: 10,
    tags: ["desk accessories", "letter holder", "wood"],
    weight: 0.8,
    dimensions: {
      width: 20.0,
      height: 10.0,
      depth: 8.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890129",
      qrCode: "...",
    },
    thumbnail: "/assets/imgs/products/classeur.png",
    images: [
      "/assets/imgs/products/classeur.png",
      "/assets/imgs/products/classeur.png",
    ],
  },
  {
    id: 8,
    title: "Thuya Wood Dolphin Figurine",
    description:
      "A beautifully hand-carved dolphin figurine, made from exquisite Thuya wood, symbolizes freedom, joy, and grace, making it an ideal piece for home décor or a thoughtful gift. Each curve of the dolphin is meticulously shaped by skilled artisans, capturing the fluidity and elegance of this beloved creature, while the natural golden-brown tones and striking grain patterns of the Thuya wood bring warmth and depth to the figurine. The polished surface enhances the wood's natural luster, offering a tactile smoothness that complements its visual appeal. This dolphin figurine is more than just a decorative piece—it embodies a sense of lightness and freedom, making it a meaningful addition to any room. Whether placed on a mantel, bookshelf, or desk, it adds a touch of nature's beauty and serenity to your space. Perfect for animal lovers, collectors, or anyone who appreciates fine craftsmanship, this Thuya wood dolphin figurine captures the essence of joy and movement, while the wood’s aromatic scent adds an extra layer of sensory delight. A timeless symbol of harmony, this figurine makes for a memorable and cherished gift or a personal treasure.",
    category: "home decor",
    price: 19.99,
    discountPercentage: 20,
    rating: 4.85,
    stock: 12,
    tags: ["home decor", "dolphin", "figurine", "wood"],
    weight: 0.9,
    dimensions: {
      width: 15.0,
      height: 8.0,
      depth: 5.0,
    },
    meta: {
      createdAt: "2024-09-12T08:56:21.618Z",
      updatedAt: "2024-09-12T08:56:21.618Z",
      barcode: "1234567890130",
      qrCode: "...",
    },
    thumbnail: "/assets/imgs/products/dolphin.png",
    images: [
      "/assets/imgs/products/dolphin.png",
      "/assets/imgs/products/dolphin.png",
    ],
  },

  {
    id: 9,
    title: "Essence Mascara Lash Princess",
    description:
      "The Essence Mascara Lash Princess is a popular mascara known for its volumizing and lengthening effects. Achieve dramatic lashes with this long-lasting and cruelty-free formula.",
    category: "beauty",
    price: 159.0,
    discountPercentage: 7.17,
    rating: 4.94,
    stock: 5,
    tags: ["beauty", "mascara"],
    weight: 2,
    dimensions: {
      width: 23.17,
      height: 14.43,
      depth: 28.01,
    },
    meta: {
      createdAt: "2024-05-23T08:56:21.618Z",
      updatedAt: "2024-05-23T08:56:21.618Z",
      barcode: "9164035109868",
      qrCode: "...",
    },
    thumbnail: "/assets/imgs/products/sendo9a.png",
    images: [
      "/assets/imgs/products/sendo9a.png",
      "/assets/imgs/products/sendo9a.png",
      "...",
    ],
  },
];

const homepage = {
  header: "Discover the Elegance of Thuya Wood",
  subHeader: "Crafting Timeless Beauty with Nature's Finest Materials",
  mainText:
    "Welcome to our Thuya wood store, where tradition meets modern craftsmanship. Our curated collection showcases the finest in handcrafted wooden decor, furniture, and collectibles. Each piece is a testament to the artistry and sustainability of Thuya wood, renowned for its distinctive grain and luxurious finish. Whether you're enhancing your home or searching for the perfect gift, we invite you to explore the natural elegance of our wood creations.",
  highlightedProducts: "Featured Collections for Every Occasion",
  footerText: "Crafted with care. Inspired by nature. Made for you.",
};
const categories = [
  {
    id: 1,
    name: "Home Decor",
    description:
      "Elegant Thuya wood pieces that add warmth and charm to any living space, from wall art to decorative bowls.",
    examples: [
      {
        name: "Thuya Wood Wall Art",
        description:
          "Handcrafted wall art featuring intricate designs carved from Thuya wood.",
        price: "$120",
      },
      {
        name: "Decorative Thuya Wood Bowl",
        description:
          "A beautifully turned bowl perfect for displaying fruits or as a centerpiece.",
        price: "$80",
      },
    ],
  },
  {
    id: 2,
    name: "Luxury Gifts",
    description:
      "Unique handcrafted Thuya wood gifts, perfect for special occasions and celebrations, offering an unforgettable touch.",
    examples: [
      {
        name: "Engraved Thuya Wood Pen Set",
        description:
          "A premium pen set with personalized engraving on Thuya wood, ideal for executives and professionals.",
        price: "$150",
      },
      {
        name: "Thuya Wood Jewelry Box",
        description:
          "An elegant jewelry box with velvet lining, crafted from fine Thuya wood.",
        price: "$200",
      },
    ],
  },
  {
    id: 3,
    name: "Artisan Furniture",
    description:
      "Bespoke furniture made from Thuya wood, blending traditional craftsmanship with modern design.",
    examples: [
      {
        name: "Thuya Wood Coffee Table",
        description:
          "A stylish coffee table with a polished Thuya wood surface and unique grain patterns.",
        price: "$600",
      },
      {
        name: "Custom Thuya Wood Chair",
        description:
          "A handcrafted chair combining comfort and sophistication, featuring Thuya wood accents.",
        price: "$450",
      },
    ],
  },
  {
    id: 4,
    name: "Collectibles & Curiosities",
    description:
      "Exclusive limited-edition collectibles and intricately designed Thuya wood curiosities for discerning collectors.",
    examples: [
      {
        name: "Limited Edition Thuya Wood Sculpture",
        description:
          "A one-of-a-kind sculpture carved from Thuya wood, celebrating unique artistry.",
        price: "$800",
      },
      {
        name: "Thuya Wood Inlay Puzzle Box",
        description:
          "A beautifully designed puzzle box with intricate Thuya wood inlays and hidden compartments.",
        price: "$175",
      },
    ],
  },
  {
    id: 5,
    name: "Jewelry & Keepsake Boxes",
    description:
      "Stunning handcrafted boxes designed for storing jewelry and treasured keepsakes, showcasing Thuya wood's natural beauty.",
    examples: [
      {
        name: "Thuya Wood Ring Holder",
        description:
          "A compact and elegant ring holder made from Thuya wood, perfect for organizing jewelry.",
        price: "$60",
      },
      {
        name: "Engraved Keepsake Box",
        description:
          "A personalized Thuya wood keepsake box with custom engraving for special memories.",
        price: "$90",
      },
    ],
  },
  {
    id: 6,
    name: "Eco-Friendly Essentials",
    description:
      "Sustainably sourced, eco-friendly Thuya wood products for everyday use, balancing beauty with function.",
    examples: [
      {
        name: "Thuya Wood Desk Organizer",
        description:
          "A practical and eco-friendly desk organizer made from Thuya wood, designed to declutter your workspace.",
        price: "$70",
      },
      {
        name: "Reusable Thuya Wood Cutlery Set",
        description:
          "An eco-conscious cutlery set crafted from Thuya wood, perfect for sustainable dining.",
        price: "$50",
      },
    ],
  },
];

const wood_decors = [
  {
    id: 1,
    name: "desk accessories",
    products: ["Pen Holders", "Desk Organizers", "Letter Openers", "Clocks"],
  },
  {
    id: 2,
    name: "souvenirs",
    products: [
      "Miniature Statues",
      "Carvings",
      "Wooden Keychains",
      "Decorative Plates",
    ],
  },
  {
    id: 3,
    name: "furniture",
    products: ["Tables", "Chairs", "Shelves", "Stools"],
  },
  {
    id: 4,
    name: "games",
    products: ["Chess", "Backgammon", "Checkers", "Dominoes"],
  },
  {
    id: 5,
    name: "boxes",
    products: [
      "Jewelry Boxes",
      "Keepsake Boxes",
      "Treasure Boxes",
      "Magic Boxes",
    ],
  },
  {
    id: 6,
    name: "decorative items",
    products: ["Vases", "Wall Art", "Candle Holders", "Picture Frames"],
  },
];

const motivationalPhrases = [
  "Limited Time Offer!",
  "Exclusive Deals for You!",
  "Low Stock – Order Now!",
  "Buy More, Save More!",
  "Free Shipping on All Orders!",
  "Satisfaction Guaranteed!",
  "Shop Now, Pay Later!",
  "Hurry! Offer Ends Soon!",
  "Limited Edition – Get Yours Before It's Gone!",
  "Perfect Gift for Loved Ones!",
];

export { categories, wood_decors, homepage, productsData };
