import "./ModelSubscribe.css";

import { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { MdOutlineMarkEmailUnread } from "react-icons/md";

const ModelSubscribe = ({ onSubmiting }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      // setIsModalOpen(true);
    }, 2000);
  }, []);

  const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  // const [msgActive, setMsgActive] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (email) {
      try {
        const response = await fetch("/api/emails", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        });

        if (response.ok) {
          // onSubmiting("Talk to you Sooon!");
          onSubmiting([
            {
              id: `125`,
              title: "Success!",
              message: `Talk to you Sooon!`,
              color: "#339900",
            },
          ]);
          setEmail(""); // Clear the email input
          setIsLoading(false);
        } else {
          // onSubmiting("Error subscribing.");

          onSubmiting([
            {
              id: `12235`,
              title: "Something Wrong!",
              message: `Could you try Again!`,
              color: "#ff9966",
            },
          ]);

          setEmail(""); // Clear the email input
          setIsLoading(false);
        }
      } catch (error) {
        // onSubmiting("Error: Could not subscribe.");
        onSubmiting([
          {
            id: `2313`,
            title: "Something Wrong!",
            message: `Could you try Again!`,
            color: "#cc3300",
          },
        ]);
      }
    }
  };

  return (
    <>
      <div className={`modal${!isModalOpen ? " closed" : ""}`} data-modal="">
        <div className="modal-close-overlay" data-modal-overlay=""></div>
        <div className="modal-content">
          <button
            className="modal-close-btn"
            data-modal-close=""
            onClick={() => setIsModalOpen(false)}
          >
            <IoMdClose />
          </button>

          <div className="newsletter-img">
            <img
              src="/assets/imgs/newsletter.jpg"
              alt="subscribe newsletter"
              width="400"
              height="400"
            />
          </div>

          <div className="newsletter">
            <form action="#" onSubmit={handleSubmit}>
              <div className="newsletter-header">
                {/* <h3 className="newsletter-title">Subscribe Newsletter.</h3> */}
                <h3 className="newsletter-title">We’re Almost Ready!</h3>

                <p className="newsletter-desc">
                  We’re busy building something awesome!
                  {/* but we’re getting close! Want to be the
                  first to know when we launch? Sign up for updates and stay in
                  the loop for exclusive offers! */}
                </p>
                <p className="newsletter-desc">
                  Be the first to know and get a <b>Special Offer</b> on your
                  first purchase!
                </p>

                {/* <p className="newsletter-desc">
                  Subscribe the <b>byDaoudi</b> to get latest products and
                  discount update.
                </p> */}
              </div>
              <input
                type="email"
                name="email"
                value={email}
                // onKeyUp={() => setEmail(email)}
                onChange={(e) => setEmail(e.target.value)}
                className="email-field"
                placeholder="Email Address"
                required
              />
              <button
                type="submit"
                className={`btn-newsletter${isLoading ? " loading" : ""}`}
              >
                {!isLoading ? "Subscribe" : <FaSpinner />}
              </button>
              {/* {message.length > 1 ? (
                <p className="message open">{message}</p>
              ) : (
                ""
              )} */}
            </form>
          </div>
        </div>
      </div>

      {!isModalOpen && (
        <button className="corner-btn" onClick={() => setIsModalOpen(true)}>
          <MdOutlineMarkEmailUnread />
        </button>
      )}
    </>
  );
};

export default ModelSubscribe;
