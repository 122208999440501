import { FaFacebook, FaInstagram, FaPinterest } from "react-icons/fa";
import { IoIosHeartEmpty, IoMdNotificationsOutline } from "react-icons/io";
import { IoBagRemoveOutline } from "react-icons/io5";

import CartDrawer from "../containers/CartDrawer";

// import { Select } from "@rewind-ui/core";
import Input from "../common/Input";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { HiOutlineHome } from "react-icons/hi";
import { BsUiRadiosGrid } from "react-icons/bs";
import { CiGrid42 } from "react-icons/ci";
import { TfiLayoutGrid2 } from "react-icons/tfi";
import { TbLayoutGrid } from "react-icons/tb";
import {
  MdOutlineMarkEmailUnread,
  MdOutlineNotifications,
} from "react-icons/md";
import { BiShoppingBag } from "react-icons/bi";

const Header = ({ isCardOpen, handleCardToggle, cartData, onDelete }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [textInform, setTextInform] = useState("");

  // const textEffect = (texts, timing, diffTime) => {
  //   // if (textInform === text) return;
  //   if (!texts) {
  //     setTextInform("no text");
  //     return;
  //   }

  //   let tm = 0;
  //   texts.forEach((text, index) => {
  //     (function (index) {
  //       setTimeout(() => {
  //         for (let i = 0; text.length > i; i++) {
  //           tm = tm + timing * i;

  //           (function (i, tm) {
  //             setTimeout(() => {
  //               // setTextInform(textInform + text[i]);
  //               console.log(textInform, i);
  //               setTextInform((e) => e + text[i]);
  //             }, tm);
  //             console.log("final", tm);
  //           })(i, tm);
  //         }
  //       }, tm);
  //     })(index);
  //     // tm = tm * timing * 1000;
  //     // tm +=  12000;

  //     // tm += diffTime;
  //     // setTextInform("");
  //   });
  // };

  // useEffect(() => {
  //   textEffect(["Coming soon", "-----"], 50, 1000);
  // }, []);

  return (
    <header>
      <div className="header-top">
        <div className="container">
          <ul className="header-social-container">
            <li>
              <Link
                className="social-link"
                to={"https://www.facebook.com/bydaoudi.decor"}
                target="_blank"
                style={{ "--social-link-bgcolor": "#1877F2" }}
              >
                <FaFacebook />
              </Link>
            </li>

            <li>
              <Link
                className="social-link"
                to={"https://www.pinterest.com/bydaoudi/"}
                target="_blank"
                style={{ "--social-link-bgcolor": "#E60023" }}
              >
                <FaPinterest />
              </Link>
            </li>

            <li>
              <Link
                className="social-link"
                to="https://www.instagram.com/bydaoudi"
                target="_blank"
                style={{
                  "--social-link-bgcolor":
                    "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB50 90%)",
                }}
              >
                <FaInstagram />
              </Link>
            </li>
          </ul>

          <div className="header-alert-news">
            <p>
              {/*<b>Free Shipping</b> - This Week Order Over - 650 DH */}
              {/* Work in progress. <b style={{ fontWeight: "700" }}>Coming soon</b> */}
              <b style={{ fontWeight: "700" }}>{textInform}</b>
            </p>
          </div>

          <div className="header-top-actions">
            <select name="currency" defaultValue={"usd"}>
              <option value="usd">USD $</option>
              <option value="mad" disabled>
                MAD DH
              </option>
              <option value="eur" disabled>
                EUR &euro;
              </option>
            </select>

            <select name="language" defaultValue={"en-US"}>
              <option value="en-US">English</option>
              <option value="es-ES" disabled>
                Espa&ntilde;ol
              </option>
              <option value="fr" disabled>
                Fran&ccedil;ais
              </option>
              <option value="ar" disabled>
                Arabic
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className="header-main">
        <div className="container">
          <span className="header-logo">
            <Link to={"/"}>
              <img
                src="/logo bydaoudi 1.png"
                alt="bydaoudi Logo"
                width="90"
                height="36"
              />
            </Link>
          </span>

          <Input />

          <div className="header-user-actions">
            {/* <button className="action-btn">
              <IoIosHeartEmpty />
              <span className="count">0</span>
            </button> */}

            <button
              className="action-btn"
              onClick={() => handleCardToggle(true)}
            >
              {/* <ion-icon name="bag-handle-outline"></ion-icon> */}
              {/* <IoBagRemoveOutline /> */}
              <BiShoppingBag /> 
              <span className="count">{cartData.length}</span>
            </button>
          </div>
        </div>
      </div>

      <CartDrawer
        // isOpen={isOpen}
        // setFn={setIsOpen}

        isCardOpen={isCardOpen}
        handleCardToggle={handleCardToggle}
        data={cartData}
        onDelete={onDelete}
      />

      <>
        {/* <div classNameName="hello-x"> HELLO 5</div> */}
        {/* <nav className="desktop-navigation-menu" style="display: nosne">
        <div className="container">
          <ul className="desktop-menu-category-list">
            <li className="menu-category">
              <a href="#" className="menu-title">
                Home
              </a>
            </li>

            <li className="menu-category">
              <a href="#" className="menu-title">
                Categories
              </a>

              <div className="dropdown-panel">
                <ul className="dropdown-panel-list">
                  <li className="menu-title">
                    <a href="#">Electronics</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Desktop</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Laptop</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Camera</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Tablet</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Headphone</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">
                      <img
                        src="./assets/images/electronics-banner-1.jpg"
                        alt="headphone collection"
                        width="250"
                        height="119"
                      />
                    </a>
                  </li>
                </ul>

                <ul className="dropdown-panel-list">
                  <li className="menu-title">
                    <a href="#">Men's</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Formal</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Casual</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Sports</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Jacket</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Sunglasses</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">
                      <img
                        src="./assets/images/mens-banner.jpg"
                        alt="men's fashion"
                        width="250"
                        height="119"
                      />
                    </a>
                  </li>
                </ul>

                <ul className="dropdown-panel-list">
                  <li className="menu-title">
                    <a href="#">Women's</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Formal</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Casual</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Perfume</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Cosmetics</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Bags</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">
                      <img
                        src="./assets/images/womens-banner.jpg"
                        alt="women's fashion"
                        width="250"
                        height="119"
                      />
                    </a>
                  </li>
                </ul>

                <ul className="dropdown-panel-list">
                  <li className="menu-title">
                    <a href="#">Electronics</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Smart Watch</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Smart TV</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Keyboard</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Mouse</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">Microphone</a>
                  </li>

                  <li className="panel-list-item">
                    <a href="#">
                      <img
                        src="./assets/images/electronics-banner-2.jpg"
                        alt="mouse collection"
                        width="250"
                        height="119"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li className="menu-category">
              <a href="#" className="menu-title">
                Men's
              </a>

              <ul className="dropdown-list">
                <li className="dropdown-item">
                  <a href="#">Shirt</a>
                </li>

                <li className="dropdown-item">
                  <a href="#">Shorts & Jeans</a>
                </li>

                <li className="dropdown-item">
                  <a href="#">Safety Shoes</a>
                </li>

                <li className="dropdown-item">
                  <a href="#">Wallet</a>
                </li>
              </ul>
            </li>

            <li className="menu-category">
              <a href="#" className="menu-title">
                Women's
              </a>

              <ul className="dropdown-list">
                <li className="dropdown-item">
                  <a href="#">Dress & Frock</a>
                </li>

                <li className="dropdown-item">
                  <a href="#">Earrings</a>
                </li>

                <li className="dropdown-item">
                  <a href="#">Necklace</a>
                </li>

                <li className="dropdown-item">
                  <a href="#">Makeup Kit</a>
                </li>
              </ul>
            </li>

            <li className="menu-category">
              <a href="#" className="menu-title">
                Jewelry
              </a>

              <ul className="dropdown-list">
                <li className="dropdown-item">
                  <a href="#">Earrings</a>
                </li>

                <li className="dropdown-item">
                  <a href="#">Couple Rings</a>
                </li>

                <li className="dropdown-item">
                  <a href="#">Necklace</a>
                </li>

                <li className="dropdown-item">
                  <a href="#">Bracelets</a>
                </li>
              </ul>
            </li>

            <li className="menu-category">
              <a href="#" className="menu-title">
                Perfume
              </a>

              <ul className="dropdown-list">
                <li className="dropdown-item">
                  <a href="#">Clothes Perfume</a>
                </li>

                <li className="dropdown-item">
                  <a href="#">Deodorant</a>
                </li>

                <li className="dropdown-item">
                  <a href="#">Flower Fragrance</a>
                </li>

                <li className="dropdown-item">
                  <a href="#">Air Freshener</a>
                </li>
              </ul>
            </li>

            <li className="menu-category">
              <a href="#" className="menu-title">
                Blog
              </a>
            </li>

            <li className="menu-category">
              <a href="#" className="menu-title">
                Hot Offers
              </a>
            </li>
          </ul>
        </div>
      </nav> */}
        <div className="mobile-bottom-navigation">
          <button className="action-btn" data-mobile-menu-open-btn>
            {/* <ion-icon name="menu-outline"></ion-icon> */}
            {/* <AiOutlineMenuUnfold /> */}

            <Link to={"/"}>
              <HiOutlineHome />
            </Link>
          </button>

          <button className="action-btn" data-mobile-menu-open-btn>
            {/* <ion-icon name="grid-outline"></ion-icon> */}
            <MdOutlineMarkEmailUnread />
          </button>

          <button className="action-btn">
            {/* <ion-icon name="home-outline"></ion-icon> */}

            {/* <BsUiRadiosGrid /> */}
            {/* <TfiLayoutGrid2 /> */}
            {/* <CiGrid42 /> */}
            <TbLayoutGrid />
          </button>

          <button className="action-btn">
            {/* <ion-icon name="heart-outline"></ion-icon> */}
            {/* <IoMdNotificationsOutline /> */}
            <MdOutlineNotifications />

            <span className="count">0</span>
          </button>

          <button className="action-btn" onClick={() => handleCardToggle(true)}>
            <BiShoppingBag />
            <span className="count">{cartData.length}</span>
          </button>
        </div>
        <nav className="mobile-navigation-menu has-scrollbar" data-mobile-menu>
          <div className="menu-top">
            <h2 className="menu-title">Menu</h2>

            <button className="menu-close-btn" data-mobile-menu-close-btn>
              <ion-icon name="close-outline"></ion-icon>
              close
            </button>
          </div>

          <ul className="mobile-menu-category-list">
            <li className="menu-category">
              <a href="#" className="menu-title">
                Home
              </a>
            </li>

            <li className="menu-category">
              <button className="accordion-menu" data-accordion-btn>
                <p className="menu-title">Men's</p>

                <div>
                  <ion-icon name="add-outline" className="add-icon"></ion-icon>
                  <ion-icon
                    name="remove-outline"
                    className="remove-icon"
                  ></ion-icon>
                </div>
              </button>

              <ul className="submenu-category-list" data-accordion>
                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Shirt
                  </a>
                </li>

                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Shorts & Jeans
                  </a>
                </li>

                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Safety Shoes
                  </a>
                </li>

                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Wallet
                  </a>
                </li>
              </ul>
            </li>

            <li className="menu-category">
              <button className="accordion-menu" data-accordion-btn>
                <p className="menu-title">Women's</p>

                <div>
                  <ion-icon name="add-outline" className="add-icon"></ion-icon>
                  <ion-icon
                    name="remove-outline"
                    className="remove-icon"
                  ></ion-icon>
                </div>
              </button>

              <ul className="submenu-category-list" data-accordion>
                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Dress & Frock
                  </a>
                </li>

                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Earrings
                  </a>
                </li>

                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Necklace
                  </a>
                </li>

                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Makeup Kit
                  </a>
                </li>
              </ul>
            </li>

            <li className="menu-category">
              <button className="accordion-menu" data-accordion-btn>
                <p className="menu-title">Jewelry</p>

                <div>
                  <ion-icon name="add-outline" className="add-icon"></ion-icon>
                  <ion-icon
                    name="remove-outline"
                    className="remove-icon"
                  ></ion-icon>
                </div>
              </button>

              <ul className="submenu-category-list" data-accordion>
                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Earrings
                  </a>
                </li>

                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Couple Rings
                  </a>
                </li>

                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Necklace
                  </a>
                </li>

                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Bracelets
                  </a>
                </li>
              </ul>
            </li>

            <li className="menu-category">
              <button className="accordion-menu" data-accordion-btn>
                <p className="menu-title">Perfume</p>

                <div>
                  <ion-icon name="add-outline" className="add-icon"></ion-icon>
                  <ion-icon
                    name="remove-outline"
                    className="remove-icon"
                  ></ion-icon>
                </div>
              </button>

              <ul className="submenu-category-list" data-accordion>
                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Clothes Perfume
                  </a>
                </li>

                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Deodorant
                  </a>
                </li>

                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Flower Fragrance
                  </a>
                </li>

                <li className="submenu-category">
                  <a href="#" className="submenu-title">
                    Air Freshener
                  </a>
                </li>
              </ul>
            </li>

            <li className="menu-category">
              <a href="#" className="menu-title">
                Blog
              </a>
            </li>

            <li className="menu-category">
              <a href="#" className="menu-title">
                Hot Offers
              </a>
            </li>
          </ul>

          <div className="menu-bottom">
            <ul className="menu-category-list">
              <li className="menu-category">
                <button className="accordion-menu" data-accordion-btn>
                  <p className="menu-title">Language</p>

                  <ion-icon
                    name="caret-back-outline"
                    className="caret-back"
                  ></ion-icon>
                </button>

                <ul className="submenu-category-list" data-accordion>
                  <li className="submenu-category">
                    <a href="#" className="submenu-title">
                      English
                    </a>
                  </li>

                  <li className="submenu-category">
                    <a href="#" className="submenu-title">
                      Espa&ntilde;ol
                    </a>
                  </li>

                  <li className="submenu-category">
                    <a href="#" className="submenu-title">
                      Fren&ccedil;h
                    </a>
                  </li>
                </ul>
              </li>

              <li className="menu-category">
                <button className="accordion-menu" data-accordion-btn>
                  <p className="menu-title">Currency</p>
                  <ion-icon
                    name="caret-back-outline"
                    className="caret-back"
                  ></ion-icon>
                </button>

                <ul className="submenu-category-list" data-accordion>
                  <li className="submenu-category">
                    <a href="#" className="submenu-title">
                      USD &dollar;
                    </a>
                  </li>

                  <li className="submenu-category">
                    <a href="#" className="submenu-title">
                      EUR &euro;
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <ul className="menu-social-container">
              <li>
                <a href="#" className="social-link">
                  <ion-icon name="logo-facebook"></ion-icon>ddx
                </a>
              </li>

              <li>
                <a href="#" className="social-link">
                  <ion-icon name="logo-twitter"></ion-icon>
                </a>
              </li>

              <li>
                <a href="#" className="social-link">
                  <ion-icon name="logo-instagram"></ion-icon>
                </a>
              </li>

              <li>
                <a href="#" className="social-link">
                  <ion-icon name="logo-linkedin"></ion-icon>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </>
    </header>
  );
};

export default Header;
