import ProductCard from "./ProductCard";
// import { productsData } from "../../data/data";

const ProductCardsList = ({ productsData, onAdding }) => {
  if (!productsData) {
    return <h1>there is no data!</h1>;
  }
  let data = productsData.slice(0, 8);
  return (
    <>
      {data.map((el) => {
        return (
          <ProductCard
            productObj={el}
            key={el.id}
            onAdding={(e) => onAdding(el)}
          />
        );
      })}
    </>
  );
};

export default ProductCardsList;
