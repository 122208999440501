import "./Button.css";

const Button = ({ children, bgColor = "royalblue", ...rest }) => {
  return (
    <button className="main-btn" style={{ background: bgColor }} {...rest}>
      {children}
    </button>
  );
};

export default Button;
