import {
  RiBox3Fill,
  RiCustomerServiceFill,
  RiDashboardHorizontalFill,
} from "react-icons/ri";
import LinkBtn from "./LinkBtn";
import "./RightPanel.css";
import { AiFillProduct } from "react-icons/ai";
import { IoLogOut, IoSearchSharp, IoSettingsSharp } from "react-icons/io5";
import { TbLayoutSidebarLeftCollapseFilled, TbTrash } from "react-icons/tb";
import { MdMarkEmailRead, MdOutlineLocalPrintshop } from "react-icons/md";
import { useEffect, useState } from "react";
import { CiMenuKebab } from "react-icons/ci";
import { RxCaretSort } from "react-icons/rx";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useFetch } from "../../hooks/useFetch";

const links = {
  body: [
    {
      id: "dashboard-01",
      icon: <RiDashboardHorizontalFill />,
      label: "DashBoard",
      active: false,
    },
    { id: "orders-02", icon: <RiBox3Fill />, label: "Orders", active: false },
    {
      id: "products-03",
      icon: <AiFillProduct />,
      label: "Products",
      active: false,
    },
    {
      id: "customer-04",
      icon: <RiCustomerServiceFill />,
      label: "Customer",
      active: false,
    },
    {
      id: "emails-list-05",
      icon: <MdMarkEmailRead />,
      label: "Emails List",
      active: true,
    }, // active set to true here
  ],
  footer: [
    {
      id: "settings-06",
      icon: <IoSettingsSharp />,
      label: "Settings",
      active: false,
    },
    { id: "logout-06", icon: <IoLogOut />, label: "Log Out", active: false },
    {
      id: "collapse-07",
      icon: <TbLayoutSidebarLeftCollapseFilled />,
      label: "Collapse",
      active: false,
    },
  ],
};

const dataX = [
  {
    id: 1,
    email: "fdigby0@typepad.com",
    date: "29/09/2024",
    status: "Bounced",
  },
  {
    id: 2,
    email: "nlowrance1@aol.com",
    date: "06/06/2024",
    status: "Subscribed",
  },
  {
    id: 3,
    email: "ldavenport2@cdbaby.com",
    date: "25/06/2024",
    status: "Subscribed",
  },
  {
    id: 4,
    email: "kkliesl3@digg.com",
    date: "26/10/2023",
    status: "Subscribed",
  },
  {
    id: 5,
    email: "fbirrell4@foxnews.com",
    date: "17/02/2024",
    status: "Re-subscribed",
  },
  {
    id: 6,
    email: "mgemeau5@biblegateway.com",
    date: "31/03/2024",
    status: "Bounced",
  },
  {
    id: 7,
    email: "cgutherson6@nih.gov",
    date: "11/02/2024",
    status: "Subscribed",
  },
  {
    id: 8,
    email: "scgutherson6@nish.gov",
    date: "11/11/2024",
    status: "Confirmed",
  },
];

const dataV = [
  {
    email: "sdf@gmail.comhello",
    date: "2024-09-19T19:00:44.954Z",
    status: "Re-subscribed",
    id: "81d672d0-844d-4201-8273-f3acc7e5f713",
  },
  {
    email: "sdf@gmail.com",
    date: "2024-09-19T19:02:45.282Z",
    status: "Subscribed",
    id: "314be652-11d2-42ec-adb1-2716cce57ba1",
  },
  {
    email: "redouanesarhane@gmail.com",
    date: "2024-09-20T15:17:41.001Z",
    status: "Confirmed",
    id: "1a0fa565-6c1b-4f46-9279-403869478c04",
  },
  {
    email: "daoudidecoration@gmail.com",
    date: "2024-09-20T15:32:06.875Z",
    status: "Subscribed",
    id: "1c4645a5-45c9-4b92-86ba-bcdad9b6fe62",
  },
  {
    email: "justatest@gmail.com",
    date: "2024-09-20T15:55:44.310Z",
    status: "Subscribed",
    id: "e4a40a8e-d66c-4f1e-9cfa-c4db3ae0332b",
  },
  {
    email: "sdf@gmail.com32",
    date: "2024-09-20T15:59:18.802Z",
    status: "Re-subscribed",
    id: "3f70342a-6be9-41aa-ba85-3c1194f27215",
  },
  {
    email: "testanotherone@gmail.com",
    date: "2024-09-20T16:00:33.944Z",
    status: "Subscribed",
    id: "b7d284d5-f94c-4bc8-b691-e0ce12d45f2a",
  },
  {
    email: "sdf2@gmail.com3",
    date: "2024-09-22T02:24:01.970Z",
    status: "Re-subscribed",
    id: "76ebd4d6-28fc-457a-b2ae-34a2787217ca",
  },
  {
    email: "example@gmail.com",
    date: "2024-09-22T22:58:35.307Z",
    status: "Subscribed",
    id: "910e03d2-4b19-45f4-b3c4-0478005f09ba",
  },
  {
    email: "fugywixe@mailinator.com",
    date: "2024-09-29T09:19:54.566Z",
    status: "Bounced",
    id: "0ee94e11-91d3-4551-88db-84ea629bc183",
  },
  {
    email: "hello@gmail.com",
    date: "2024-10-01T22:00:10.544Z",
    status: "Confirmed",
    id: "8e93ddbf-1e00-4704-8466-062070b7d89c",
  },
  {
    email: "hello@gmail.comm",
    date: "2024-10-11T19:19:24.117Z",
    status: "Subscribed",
    id: "c5660025-c81c-4076-846f-bd2c621f8cac",
  },
  {
    email: "sdd3f@gmail.com",
    date: "2024-10-12T21:43:33.995Z",
    status: "Bounced",
    id: "9bd271c8-a2a2-46a0-8360-276cde80e805",
  },
  {
    email: "asdf@sdfgs",
    date: "2024-10-12T22:21:51.479Z",
    status: "Re-subscribed",
    id: "c79f34e6-5eba-41ce-8163-852642e56f10",
  },
  {
    email: "sdfgsdf@sdfg",
    date: "2024-10-12T22:22:06.524Z",
    status: "Bounced",
    id: "67c55d64-6981-4b55-81f6-aea97c5c91f0",
  },
  {
    email: "afE@df",
    date: "2024-10-12T22:22:47.454Z",
    status: "Confirmed",
    id: "c9925126-54ef-4457-892f-feb160ba1124",
  },
  {
    email: "asf@sdfg",
    date: "2024-10-12T22:22:55.799Z",
    status: "Bounced",
    id: "2550549d-ebab-453f-b2d4-38f95e9078d5",
  },
  {
    email: "asdf@sdfg23",
    date: "2024-10-12T22:23:38.719Z",
    status: "Bounced",
    id: "01d49f5c-f88c-45dc-820c-fa6b2d3146b3",
  },
  {
    email: "asdf@asd",
    date: "2024-10-12T22:24:33.950Z",
    status: "Confirmed",
    id: "3e4178c4-8611-407b-809e-11f8213e6796",
  },
  {
    email: "asdf@sdf",
    date: "2024-10-12T22:24:41.214Z",
    status: "Bounced",
    id: "036ce3cc-90e4-401d-98fc-10da266bf4dd",
  },
  {
    email: "sdf@sdf",
    date: "2024-10-12T22:38:56.764Z",
    status: "Confirmed",
    id: "c81c8a09-1796-4e44-a86a-544b04eadd2e",
  },
  {
    email: "sdfgsdf@sdfgs",
    date: "2024-10-13T00:06:39.704Z",
    status: "Re-subscribed",
    id: "9c42afac-e940-402b-8565-217f110067c9",
  },
  {
    email: "sdf@gmail.coms",
    date: "2024-10-13T00:06:47.130Z",
    status: "Subscribed",
    id: "8eebbf53-0973-4f25-b5e3-c71b7f6df18c",
  },
  {
    email: "sdfsdfsdf@dff",
    date: "2024-10-15T08:53:56.110Z",
    status: "Bounced",
    id: "73f6c96b-dfe1-4788-83e2-73b2c45c9292",
  },
  {
    email: "text@gmail.com",
    date: "2024-10-22T10:14:35.265Z",
    status: "Re-subscribed",
    id: "5825c687-feaa-4726-8426-bad2286c49a3",
  },
  {
    email: "hellohello1@gmail.com",
    date: "2024-10-23T22:20:39.985Z",
    status: "Subscribed",
    id: "e6ae687d-3983-4333-b3bc-9915c142797a",
  },
];

const RightPanel = () => {
  const [activeLink, setActiveLink] = useState("emails-list-05");
  return (
    <div className="navigation-panel">
      <div className="navigation-panel-header">
        <img src="/logo bydaoudi 1.png" alt="" />
        <span>ByDaoudi</span>
      </div>
      <div className="navigation-panel-body">
        {links.body.map((link) => (
          <LinkBtn
            key={link.id}
            active={link.id === activeLink}
            onClick={(e) => setActiveLink(link.id)}
          >
            {link.icon}
            {link.label}
          </LinkBtn>
        ))}

        {/* <LinkBtn active={true}> 
          <MdMarkEmailRead />
          Emails List
        </LinkBtn> */}
      </div>
      <div className="navigation-panel-footer">
        {links.footer.map((link) => (
          <LinkBtn
            key={link.id}
            active={link.id === activeLink}
            onClick={(e) => setActiveLink(link.id)}
          >
            {link.icon}
            {link.label}
          </LinkBtn>
        ))}
      </div>

      {/* <LinkBtn>helloBtn</LinkBtn> */}
    </div>
  );
};

const DisplayPanel = () => {
  return (
    <div
      style={{
        flex: 1,
        padding: "0 2rem",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Table></Table>
    </div>
  );
};

const Table = ({ children }) => {
  // const AllData = dataV;
  const status = ["Subscribed", "Confirmed", "Re-subscribed", "Bounced"];

  const {
    // data: AllData = dataV,
    data: AllData,
    isLoading: isLoadingEmails,
    error: emailsDataError,
  } = useFetch("/api/emails/all");
  // } = useFetch("https://dummyjson.com/products");

  // console.log(data , isLoading, error);

  // const [AllData, setAllData] = useState();

  const [dataDisplayed, setDataDisplayed] = useState(AllData);
  useEffect(() => {
    setDataDisplayed(AllData);
  }, [AllData]);

  console.log("datt", AllData);

  const [allSelected, setAllSelected] = useState(false);
  const [selectedDtRow, setSelectedDtRow] = useState([]);

  const [activeTabfilterStatus, setActiveTabfilterStatus] = useState("all");

  const handlePrint = () => {
    // return;

    if (selectedDtRow.length === 0) {
      // eslint-disable-next-line no-restricted-globals
      let allPrinted = confirm(
        `do u want print ALL emails(${AllData.length})!`
      );
      if (allPrinted) console.log(AllData);
      return;
    }

    // AllData.slice().filter((el) => selectedDtRow.includes(el));

    // eslint-disable-next-line no-restricted-globals
    let displayedprinted = confirm(
      `do u want print SELECTED emails(${
        // selectedDtRow.slice().filter((el) => AllData.indexOf(el) > -1).length

        AllData.slice().filter((el) => el)

        // selectedDtRow.slice().filter((el) => el)
      })!`
    );
    if (displayedprinted)
      // console.log(

      // selectedDtRow.slice().filter((el) => {
      //   console.log(el);
      //   console.log(AllData.includes(el));
      //   // return AllData;
      // });

      AllData.slice().filter((el) => {
        console.log(el);
        selectedDtRow.slice().filter((elt) => {
          console.log(elt);
          // el === elt
        });
      });
    // selectedDtRow.slice().filter((el) => AllData.indexOf(el) > -1)
    // );
  };

  // const { data, isLoading, error } = useFetch("/api/emails/all");

  return (
    <div className="data" style={{ margin: "2rem 0" }}>
      <div className="data-stats">
        <div
          className={`data-stats-label${
            activeTabfilterStatus === "all" ? " active" : ""
          }`}
          onClick={(e) => {
            setActiveTabfilterStatus((e) => "all");
            setDataDisplayed((e) => AllData);
          }}
        >
          All
          <span className="num-label">{AllData.length}</span>
        </div>

        {status.map((statusel, i) => (
          <div
            key={i}
            className={`data-stats-label${
              activeTabfilterStatus === statusel.toLowerCase() ? " active" : ""
            }
              `}
            onClick={(e) => {
              setActiveTabfilterStatus((e) => statusel.toLowerCase());
              setDataDisplayed((e) =>
                AllData.slice().filter(
                  (el) => el.status.toLowerCase() === statusel.toLowerCase()
                )
              );
            }}
          >
            {statusel}
            <span className="num-label">
              {
                AllData.filter(
                  (el) => el.status.toLowerCase() === statusel.toLowerCase()
                ).length
              }
            </span>
          </div>
        ))}
      </div>
      <div className="data-filter">
        <div className="btns-actions">
          <BtnFilterIcon>
            <FaRegCircleCheck style={{ color: "#4BB543" }} />
            Mark as confirmed
          </BtnFilterIcon>
          <BtnFilterIcon onClick={handlePrint}>
            <MdOutlineLocalPrintshop style={{ color: "#5bc0de" }} />
            Print
          </BtnFilterIcon>
          <BtnFilterIcon>
            <TbTrash style={{ color: "#bb2124" }} />
            Delete
          </BtnFilterIcon>
        </div>
        <div className="search">
          <SearchInput />
        </div>
      </div>

      <table className="data-table table">
        <thead>
          <tr className="trow thead">
            <th className="tdata">
              <label>
                <input
                  type="checkbox"
                  className="input"
                  checked={allSelected}
                  onChange={(e) => {
                    setAllSelected(e.target.checked);
                    setSelectedDtRow(() => {
                      // console.log(allSelected);
                      // console.log(e.target.checked);

                      return e.target.checked
                        ? //  && selectedDtRow.length !== data.length
                          [...dataDisplayed.slice().map((el) => el.id)]
                        : [];
                    });
                  }}
                />
                <span className="custom-checkbox"></span>
              </label>
            </th>
            <th className="tdata">ID</th>
            <th className="tdata email">Email Address</th>
            <th className="tdata">
              <span style={{ cursor: "pointer" }}>Due Date</span>
              <RxCaretSort style={{ color: "#007eff", fontSize: "1.1rem" }} />
            </th>
            <th className="tdata">
              <span style={{ cursor: "pointer" }}>Status</span>
              <RxCaretSort style={{ color: "#007eff", fontSize: "1.1rem" }} />
            </th>
            <th className="tdata">Actions</th>
          </tr>
        </thead>

        <tbody>
          {emailsDataError ? (
            <div style={{ textAlign: "center", padding: "1rem 0" }}>
              there some error, please refresh again...
            </div>
          ) : isLoadingEmails ? (
            <div style={{ textAlign: "center", padding: "1rem 0" }}>
              Loading...
            </div>
          ) : (
            dataDisplayed.map((el, i) => (
              <TableRow
                id={i + 1}
                dataRow={el}
                key={el.id}
                isCheckedOut={selectedDtRow.includes(el.id)}
                setSelectedDtRow={setSelectedDtRow}
                setAllSelected={setAllSelected}
              />
            ))
          )}
        </tbody>

        <tfoot
          style={{
            position: "sticky",
            bottom: 0,
            background: "#eee",
            textAlign: "center",
            padding: "2rem",
          }}
        >
          footer table data
        </tfoot>
      </table>
    </div>
  );
};

const TableRow = ({
  id,
  dataRow,
  isCheckedOut = false,
  setSelectedDtRow,
  setAllSelected,
}) => {
  // const [isChecked, setIsChecked] = useState(isCheckedOut);

  // const date = new Date(dataRow.date);

  // // const date = new Date();
  // const formatter = new Intl.DateTimeFormat("en-US", { dateStyle: "short" });
  // const formattedDate = formatter.format(date);
  // console.log(formattedDate);

  const statusClr = () => {
    if (dataRow.status.toLowerCase() === "confirmed") return "#22bb3370";
    if (dataRow.status.toLowerCase() === "subscribed") return "#5bc0de70";
    if (dataRow.status.toLowerCase() === "re-subscribed") return "#f0ad4e70";
    if (dataRow.status.toLowerCase() === "bounced") return "#bb212470";

    return null;
  };

  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dt = new Date(dataRow.date);

  return (
    <tr
      className="trow"
      style={isCheckedOut ? { background: "#b5b7b829" } : {}}
    >
      <td className="tdata">
        <label>
          <input
            type="checkbox"
            className="input"
            checked={isCheckedOut}
            onChange={(e) => {
              setSelectedDtRow((e) =>
                e.includes(dataRow.id)
                  ? e.slice().filter((el) => {
                      setAllSelected(false);
                      return dataRow.id !== el;
                    })
                  : [...e, dataRow.id]
              );
            }}
          />
          <span className="custom-checkbox"></span>
        </label>
      </td>
      <td className="tdata iddt">{String(id).padStart(3, "0")}</td>
      <td className="tdata emaildt">{dataRow.email}</td>
      <td className="tdata datedt">{`${String(dt.getDate()).padStart(2, "0")} ${
        monthNamesShort[dt.getMonth()]
      } ${dt.getFullYear()}`}</td>
      <td className="tdata statusdt">
        <span style={statusClr() ? { background: statusClr() } : {}}>
          {dataRow.status}
        </span>
      </td>
      <td className="tdata actionsdt">
        <span className="icon">
          <CiMenuKebab />
        </span>
      </td>
    </tr>
  );
};

const BtnFilterIcon = ({ children, ...rest }) => {
  return (
    <button className="btn-filter-icon" {...rest}>
      {children}
    </button>
  );
};

const SearchInput = ({ ...rest }) => {
  return (
    <div className="search-field" {...rest}>
      <IoSearchSharp />
      <input type="text" name="" id="" placeholder="Search for email..." />
    </div>
  );
};

export { RightPanel, DisplayPanel };
