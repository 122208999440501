import { useEffect, useState } from "react";
import "./ImageGallery.css";

const ImageGallery = ({ imagesObj }) => {
  // const arr = [...Array.from(12).keys];
  // console.log(arr);
  // const images = ["/assets/imgs/products/chess roba3iya.png"];
  // const images = ["/assets/imgs/products/chess roba3iya.png", ...imagesObj];
  // const images = imagesObj;

  // console.log("hello images", images);

  // const [images, setImages] = useState([]);

  // const [activeImg, setActiveImg] = useState(images[0]);

  // useEffect(() => {
  //   setImages((e) => imagesObj);
  //   // setActiveImg(images[0]);
  //   setActiveImg(images[0]);

  //   console.log("-----------");
  //   console.log(images);
  //   console.log(activeImg);
  //   console.log("-----------");
  // }, [activeImg]);

  // console.log("images", images);

  const [activeImg, setActiveImg] = useState(imagesObj[0]);
  // const [images, setImages] = useState(imagesObj[0]);

  useEffect(() => {
    setActiveImg(imagesObj[0]);
  }, [imagesObj]);

  // useEffect(() => {
  //   setImages(imagesObj[0]);
  // }, [imagesObj]);

  return (
    <div className="product__image-gallery">
      <div className="product__main-image">
        <img src={activeImg} alt="" />
      </div>
      <div className="product__thumbnail-gallery">
        {/* {imagesObj.map((el) => (
          <div className="product__second-image active">
            <img
              // src="/assets/imgs/products/chess roba3iya.png"
              src={imagesObj[0] ? imagesObj[0] : "/assets/imgs/no-photo.jpg"}
              onClick={(e) => setActiveImg(e.target.src)}
              alt=""
            />
          </div>
        ))} */}

        <div className="product__second-image active">
          <img
            // src="/assets/imgs/products/chess roba3iya.png"
            src={imagesObj[0] ? imagesObj[0] : "/assets/imgs/no-photo.jpg"}
            onClick={(e) => setActiveImg(e.target.src)}
            alt=""
          />
        </div>
        <div className="product__second-image">
          <img
            // src="/assets/imgs/products/chess roba3iya 2.png"
            src={imagesObj[1] ? imagesObj[1] : "/assets/imgs/no-photo.jpg"}
            // src="https://cdn.dummyjson.com/products/images/beauty/Essence%20Mascara%20Lash%20Princess/1.png"
            alt=""
            onClick={(e) => setActiveImg(e.target.src)}
          />
        </div>
        <div className="product__second-image">
          <img
            // src="/assets/imgs/products/classeur.png"
            src={imagesObj[2] ? imagesObj[2] : "/assets/imgs/no-photo.jpg"}
            alt=""
            onClick={(e) => setActiveImg(e.target.src)}
          />
        </div>
        <div className="product__second-image">
          <img
            // src="/assets/imgs/products/sendo9a.png"
            src={imagesObj[3] ? imagesObj[3] : "/assets/imgs/no-photo.jpg"}
            alt=""
            onClick={(e) => setActiveImg(e.target.src)}
          />
        </div>

        {/* <img
        src="thumb1.jpg"
        alt="Thumbnail 1"
        className="product__thumbnail"
      />

      <img
        src="thumb2.jpg"
        alt="Thumbnail 2"
        className="product__thumbnail"
      /> */}
      </div>
    </div>
  );
};

export default ImageGallery;
